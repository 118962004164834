export class SearchTelemetryAjaxCaller {

    private static blockTelemetryEvent: boolean;
    private static headersOfTelemetryRequest = {
        type: 'application/json'
    };

    public static postData(data: SearchTelemetryData) {
        
        if (SearchTelemetryAjaxCaller.blockTelemetryEvent) return;

        SearchTelemetryAjaxCaller.blockTelemetryEvent = true;
        setTimeout(() => (this.blockTelemetryEvent = false), 1000);
        let route = '';
        switch (data.eventType) {
            case SearchTelemetryEventType.HymUsed:
                route = 'api/shopsearch-web/telemetry/hym/events';
                break;

            case SearchTelemetryEventType.SearchUsed:
                route = 'api/shopsearch-web/telemetry/search/events';
                break;

            default:
                throw new RangeError(
                    `Event type ${data.eventType} is not valid.`
                );
        }
        const apiUrl = data.magicLink + route;

        const requestUrl = new URL(apiUrl);
        
        if (navigator.sendBeacon) {
            const payload = new Blob(
                [JSON.stringify(data)],
                SearchTelemetryAjaxCaller.headersOfTelemetryRequest
            );
            navigator.sendBeacon(requestUrl, payload);
            return;
        }

        setTimeout(() => {
            fetch(requestUrl, {
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            });
        }, 0);
    }
}

export interface ISearchTelemetryData {
    readonly eventType: SearchTelemetryEventType;
    term: string;
}

export enum SearchTelemetryEventType {
    Unknown,
    HymUsed,
    SearchUsed
}

export class SearchTelemetryData implements ISearchTelemetryData
{
    public term: string;
    public magicLink: string;
    private origin: string;
    private contentType: string;
    private salesArticleNo: string;
    private position: number;
    private pageViewLogDataId: string;
    private portal: string;
    private culture: string;
    public eventTypeField: SearchTelemetryEventType =
    SearchTelemetryEventType.Unknown;

    get eventType(): SearchTelemetryEventType {
        return this.eventTypeField;
    }

    constructor(
        stm: string,
        pageViewLogDataId: string,
        portal: string,
        culture: string,
        magicLink: string
    ) {
        this.pageViewLogDataId = pageViewLogDataId;
        this.portal = portal;
        this.culture = culture;
        this.setData(stm);
        this.magicLink = magicLink;
    }


    private setData(stm: string) {
        const keyValue: string[] = stm.split(';');
        keyValue.forEach((element: string) => {
            const split: string[] = element.split('=');
            const key: string = split[0];
            const value: string = split[1];

            switch (key) {
                case 'Term':
                    // term can contain semicolon which is escaped via some similar unicode char
                    this.term = value.replace('﹔', ';');
                    break;
                case 'Origin':
                    this.origin = value;
                    break;
                case 'ContentType':
                    this.contentType = value;
                    break;
                case 'Position':
                    this.position = parseInt(value, 10);
                    break;
                case 'SalesArticleNo':
                    this.salesArticleNo = value;
                    break;
                default:
                    // ignore new keys
                    break;
            }
        });

        if (this.salesArticleNo)
            this.eventTypeField = SearchTelemetryEventType.SearchUsed;
        else if (this.origin)
            this.eventTypeField = SearchTelemetryEventType.HymUsed;
    }
}

export class PageViewLogDataId
{
    private static instance: PageViewLogDataId;

    private pageViewLogDataId: string;

    constructor() {
        if (PageViewLogDataId.instance)
            throw new Error(
                `Instantiation of GetPageViewLogDataId failed:
                Use GetPageViewLogDataId.getInstance()
                instead of new GetPageViewLogDataId()`
            );
    }

    public static getInstance(): PageViewLogDataId {
        if (!PageViewLogDataId.instance)
            PageViewLogDataId.instance = new PageViewLogDataId();

        return PageViewLogDataId.instance;
    }

    public get(): string {
        if (!this.pageViewLogDataId) this.set();

        return this.pageViewLogDataId;
    }

    private set(): void {
        this.pageViewLogDataId =
            document.documentElement.dataset.pageViewLogDataId;
    }
}
