import * as React from 'react';
import classNames from 'classnames';
import styles from './colour-value.scss';
import { IFilterValueProps } from '../filterValue/filter-value.d';
import * as helper from '../../../Common/html-helper';
import TabNavigationHelper from '../../../Common/tabNavigationHelper';

export default class ColourValue extends React.Component<IFilterValueProps> {
    private tabNav: TabNavigationHelper;
    
    constructor(props) {
        super(props);
        this.state = {};

        this.tabNav = TabNavigationHelper.instance;
        this.onValueClicked = this.onValueClicked.bind(this);
    }

    public render() {
        return (
            <div className={classNames(styles.filter_colour_value,
                {
                    [styles.selected_value]: this.props.isSelected,
                    [styles.disabled_color_text]: this.props.filterPropertyValue.articlesAmount === 0,
                })}
                data-testid='filter_colour_value'
                ref={(el)=> this.tabNav.setFocusAtt(el)}
                onClick={this.onValueClicked}
                onKeyDown={this.tabNav.handleKeyDown}
            >
                {this.props.filterPropertyValue.iconSrc ?
                    (
                        <div className={styles.icon_container}>
                            <img src={this.props.filterPropertyValue.iconSrc} loading='lazy'/>
                        </div >
                    )
                    : null
                }
                <div className={classNames(styles.colour_image,
                    {
                        [styles.red]: this.props.filterPropertyValue.id === '1',
                        [styles.blue]: this.props.filterPropertyValue.id === '2',
                        [styles.orange]: this.props.filterPropertyValue.id === '3',
                        [styles.yellow]: this.props.filterPropertyValue.id === '5',
                        [styles.grey]: this.props.filterPropertyValue.id === '6',
                        [styles.green]: this.props.filterPropertyValue.id === '7',
                        [styles.white]: this.props.filterPropertyValue.id === '8',
                        [styles.brown]: this.props.filterPropertyValue.id === '9',
                        [styles.violet]: this.props.filterPropertyValue.id === '10',
                        [styles.disabled_color_image]: this.props.filterPropertyValue.articlesAmount === 0,
                    })} />
                <div className={styles.filter_title}>
                    {helper.decodeHTML(this.props.filterTitleMlt)}
                </div>
                <div className={styles.article_amount}>{this.props.filterPropertyValue.articlesAmount}</div>
            </div>
        );
    }

    private onValueClicked() {
        if (this.props.isSelected)
            this.props.removeSelectedFilter(this.props.filter);
        else
            this.props.addSelectedFilter(this.props.dimension, this.props.filterPropertyValue);
    }
}
