import * as helper from '../../Common/html-helper';
import * as React from 'react';
import * as FasButtonTypes from './fas-button.d';
import FilterIcn2021 from '../../Assets/svg/filter_2021';
import ArticleCount from '../articleCount/article-count';
import classNames from 'classnames';
import * as Constants from '../../Common/constants';
import styles from './fas-button.scss';
import SortIcn2021 from '../../Assets/svg/sort_2021';
import { SortContext } from '../globalState/sortContextProvider';
import { ViewType } from '../../Common/enums';
import { publish, subscribe, unsubscribe } from '../../Common/customEventHelper';

export default class FasButton extends React.Component<FasButtonTypes.IFasButtonProps,
    FasButtonTypes.IFasButtonState> {
    static contextType = SortContext;

    constructor(props) {
        super(props);
        this.state = {
            articleNumber: null,
            showButton: false,
        };

        subscribe('ESPP.FilterAndSort.HideFasButton',
            () => { this.setState({ showButton: false }); });
        subscribe('ESPP.FilterAndSort.ShowFasButton',
            () => { this.setState({ showButton: true }); });
        subscribe('ESPP.FilterAndSort.UpdateGlobalStateDependencies',
            () => { this.updateGlobalStateDependencies(); });
    }

    public componentWillUnmount(): void {
        unsubscribe('ESPP.FilterAndSort.HideFasButton',
            () => { this.setState({ showButton: false }); });
        unsubscribe('ESPP.FilterAndSort.ShowFasButton',
            () => { this.setState({ showButton: true }); });
        unsubscribe('ESPP.FilterAndSort.UpdateGlobalStateDependencies',
            () => { this.updateGlobalStateDependencies(); });
    }

    public render() {
        return (
            <div className={classNames(styles.fas_button,
                { [styles.breadcrumb_only]: this.isBreadcrumbOnly() }, helper.getPortalSelector(this.props.isGlobal))}>
                {this.renderArticleNumber()}
                {this.renderFilterButton()}
                {this.renderSortButton()}
            </div>
        );
    }

    public sendEvent = (payload: string) => {
        publish('ESPP.MainSidePanel.ShouldOpen', payload);
    }

    private renderFilterButton() {
        return (
            <div className={classNames(styles.filter_button_wrapper,
                {[styles.hidden]: !this.state.showButton && !this.isBreadcrumbOnly()})}>
                <div
                    className={classNames(styles.filter_button,
                        { [styles.filter_active]: this.props.globalState.selectedFilters.length > 0 })}
                    data-testid='filter_button'
                    onClick={() => { this.sendEvent('Filter') }}>
                    {
                        this.props.globalState.selectedFilters.length > 0 && <div className={styles.filter_count_container}>
                            <div className={styles.filter_count}>{this.props.globalState.selectedFilters.length}</div>
                    </div>
                    }
                    <FilterIcn2021 />
                    <div className={styles.button_title}>{helper.decodeHTML(this.props.l10n.filter)}</div>
                </div>
            </div>
        );
    }

    private renderSortButton() {
        if (this.isBreadcrumbOnly())
            return (
                <SortContext.Consumer>
                    {(context) => (
                        <div className={classNames(styles.sort_button_wrapper)}>
                            <div className={classNames(styles.sort_button)}
                            data-testid='sort_button'
                            onClick={() => {
                                this.sendEvent('Sort');
                            }}>
                                <SortIcn2021 />
                                <div className={styles.sort_text}>

                                    {context.sortPropertyList.find(f => f.selected) ?
                                        helper.decodeHTML(context.sortPropertyList.find(f => f.selected).title) :
                                        helper.decodeHTML(this.props.l10n.sort)}
                                </div>
                            </div>
                        </div>
                    )}
                </SortContext.Consumer>
            );
        else
            return null;
    }

    private renderArticleNumber() {
        if (this.props.globalState.filterModel &&
            this.isBreadcrumbOnly())
            return (
                <ArticleCount
                    l10n={this.props.l10n}
                    articleNumber={this.state.articleNumber}
                    globalState={this.props.globalState}
                ></ArticleCount>
            );
        else
            return null;
    }

    private isBreadcrumbOnly(): boolean {
        const filterExist: boolean =
            this.props.globalState.filterModel !== null && this.props.globalState.filterModel.filters != null
            && this.props.globalState.filterModel.filters
            .filter((x) => x.name !== Constants.DELIVERY_TIME).length > 1;

        const isSearchResultPage: boolean = this.props.viewType === ViewType.Search;
        const isSpecialCategoryPage: boolean =
            this.props.navigationKey === 'SALE' || this.props.navigationKey === 'Neuheiten';
        const onlyHaveDeliveryTime: boolean = filterExist &&
            this.props.globalState.filterModel.filters
                .filter((x) => x.name !== Constants.DELIVERY_TIME).length === 0;

        const isKioskFilter = !!this.props.wwsId;

        if (isKioskFilter)
            return false;

        return filterExist ? isSearchResultPage || isSpecialCategoryPage || onlyHaveDeliveryTime : true;
    }

    private updateGlobalStateDependencies(): void {
        this.setState({ articleNumber: this.props.globalState.filterModel.matchingArticleCount });
    }
}
