import * as React from 'react';
import styles from './header.scss';
import classnames from 'classnames';
import FilterIcn2021 from '../../../Assets/svg/filter_2021';
import ProductFinderIcn2021 from '../../../Assets/svg/productfinder_2021';
import CrossIcn2021 from '../../../Assets/svg/cross_2021';
import SpinningArrow2021 from '../../../Assets/svg/spinning_arrow_2021';
import Sort2021 from '../../../Assets/svg/sort_2021';
import * as helper from '../../../Common/html-helper';
import { ContentType } from '../sidePanel/contentType';
import TabNavigationHelper from '../../../Common/tabNavigationHelper';

export default class Header extends React.Component<{
    onStateChange; onClose; onReset; localizations; selectedTab: ContentType; isShow;
    shouldShowProductFinder; productFinderKey; isSortContent; shouldShowFilters;
},
    { showResetButton: boolean }> {
    private filterButtonRef = React.createRef<HTMLDivElement>();
    private finderButtonRef = React.createRef<HTMLDivElement>();
    private heighlightRef = React.createRef<HTMLDivElement>();
    private inited = false;
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;
        this.state = { showResetButton: false };
        if (typeof window !== 'undefined') {
            window.addEventListener('handleResetButton', 
                ((e: CustomEvent) => { this.setState({ showResetButton: e.detail }); }) as EventListener);
            window.addEventListener('resize', helper.throttle(this.forceUpdate.bind(this), 300));
        }
    }

    componentDidMount(): void {
        if(this.filterButtonRef.current)
            this.tabNav.setFocusAtt(this.filterButtonRef.current);

        if(this.finderButtonRef.current)
            this.tabNav.setFocusAtt(this.finderButtonRef.current);
    }

    public componentDidUpdate() {
        if (this.props.isShow) {
            if (this.inited) 
                this.updateSwitchAnimation();
            else {
                this.inited = true;
                window.setTimeout(() => { this.updateSwitchAnimation(); }, 180);
                // retrigger for slow browsers (IE11)
                window.setTimeout(() => { this.updateSwitchAnimation(); }, 500);
            }
        }
        else
            this.inited = false;
    }

    public render() {
        return (
            <div className={styles.msp_header} ref={(el)=> this.tabNav.setGroupAtt(el)}>
                {this.renderProperHeader()}
                {this.props.selectedTab !== ContentType.FilterContent && (
                    <div className={classnames(styles.msp_header_button, styles.msp_reset_button,
                        {[styles.hidden]: !this.state.showResetButton})}
                        data-testid='msp_header_reset_button'
                        onClick={this.props.onReset}
                        ref={(el)=> this.tabNav.setFocusAtt(el)}>
                        <SpinningArrow2021 />
                    </div>
                )}
                <div className={classnames(styles.msp_header_button, styles.msp_close_button)}
                    data-testid='msp_header_close_button'
                    onClick={this.props.onClose}
                    ref={(el)=> this.tabNav.setFocusAtt(el)}
                    onKeyDown={this.tabNav.handleKeyDown}><CrossIcn2021 /></div>
            </div>
        );
    }

    private renderProperHeader() {
        if(this.props.isSortContent)
            return this.renderSortTitle();

        if (this.props.shouldShowFilters)
            return this.props.shouldShowProductFinder ? this.renderTabSwitch() : this.renderFilterTitle();

        return this.props.shouldShowProductFinder ? this.renderProductFinderOnly() : null;
    }

    private renderProductFinderOnly() {
        return (
            <div className={styles.msp_header_label}>
                <ProductFinderIcn2021 />
                <div className={styles.msp_header_label_title}>
                    {this.props.productFinderKey === ContentType.JacketFinderContent &&
                        helper.decodeHTML(this.props.localizations.jacketFinder)}
                    {this.props.productFinderKey === ContentType.TrouserFinderContent &&
                        helper.decodeHTML(this.props.localizations.productFinder)}
                    {this.props.productFinderKey === ContentType.ShoeFinderContent &&
                        helper.decodeHTML(this.props.localizations.shoeFinder)}
                </div>
            </div>
        );
    }

    private renderTabSwitch() {
        return (
            <div className={styles.msp_header_switcher} ref={(el)=> this.tabNav.setGroupAtt(el)}>
                <div ref={this.heighlightRef} 
                    className={classnames(styles.msp_button_heighlight,
                    {[styles.left]: this.props.selectedTab === ContentType.FilterContent},
                    {[styles.right]: this.props.selectedTab !== ContentType.FilterContent},
                    {[styles.animated]: this.inited})} />
                <div ref={this.filterButtonRef} 
                    className={classnames(styles.msp_filter_button, 
                        {[styles.selected]:this.props.selectedTab === ContentType.FilterContent})}
                    data-testid='msp_filter_button'
                    onClick={this.props.onStateChange} data-content={ContentType.FilterContent}>
                    <FilterIcn2021 />
                    {helper.decodeHTML(this.props.localizations.filter)}
                </div>
                <div ref={this.finderButtonRef}
                    className={classnames(styles.msp_trouserfinder_button,
                    {[styles.selected]: this.props.selectedTab !== ContentType.FilterContent})}
                    data-testid={'msp_' + this.props.productFinderKey}
                    onClick={this.props.onStateChange} data-content={this.props.productFinderKey}>
                    <ProductFinderIcn2021 />
                    {this.props.productFinderKey === ContentType.JacketFinderContent &&
                        helper.decodeHTML(this.props.localizations.jacketFinder)}
                    {this.props.productFinderKey === ContentType.TrouserFinderContent &&
                        helper.decodeHTML(this.props.localizations.productFinder)}
                    {this.props.productFinderKey === ContentType.ShoeFinderContent &&
                        helper.decodeHTML(this.props.localizations.shoeFinder)}
                </div>
            </div>
        );
    }

    private renderFilterTitle() {
        return (
            <div className={styles.msp_header_label}>
                <FilterIcn2021 />
                <div className={styles.msp_header_label_title}>
                    {helper.decodeHTML(this.props.localizations.filter)}
                </div>
            </div>
        );
    }

    private renderSortTitle() {
        return (
            <div className={styles.msp_header_label}>
                <Sort2021 />
                <div className={styles.msp_header_label_title}>
                    {helper.decodeHTML(this.props.localizations.sort)}
                </div>
            </div>
        );
    }

    private updateSwitchAnimation() {
        if (this.filterButtonRef.current && this.finderButtonRef.current) {
            const filterButtonWidth = this.filterButtonRef.current.offsetWidth;
            const finderButtonWidth = this.finderButtonRef.current.offsetWidth;
            if (this.props.selectedTab === ContentType.FilterContent) {
                this.heighlightRef.current.style.width = filterButtonWidth.toString() + 'px';
                this.heighlightRef.current.style.left = '0px';
                this.heighlightRef.current.style.right = finderButtonWidth.toString() + 'px';
            } else {
                this.heighlightRef.current.style.width = finderButtonWidth.toString() + 'px';
                this.heighlightRef.current.style.right = '0px';
                const finialValue = filterButtonWidth + 10;
                this.heighlightRef.current.style.left = finialValue.toString() + 'px';
            }
        }
    }
}
