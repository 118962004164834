import { IFilteringResult, ISalesDesignationView } from '../articleTileGrid/article-tile/article-tile.d';
import { GlobalState } from '../globalState/globalState';

export class BookmarkController {
    private _articleList: ISalesDesignationView[];

    constructor(articleList: ISalesDesignationView[], globalState: GlobalState) {
        this._articleList = articleList;
        if (typeof window !== 'undefined')
            this.subscribeToBookmark();

        // for tests
        if(globalState)
            globalState.registerOnArticlesChanged(this.onNewArticles.bind(this));
    }

    public onNewArticles(result: IFilteringResult) {
        this._articleList = result.articles;
    }

    public updateArticleList(articles: ISalesDesignationView[]): void {
        this._articleList = this._articleList.concat(articles);
    }

    public getPayloadById(id: string) {
        let article: ISalesDesignationView;

        const isTeaserView = 
            document.getElementsByClassName('fas_teaser_suggestion_container').length > 0;
        if (isTeaserView) {
            const slicedTeaserId = id.split('_')[0]; // removes grid number "_n"
            article = this._articleList.find(article => article.defaultSalesArticleNo === slicedTeaserId);
        } else {
            article = this._articleList.find(article => article.defaultSalesArticleNo === id);
        }
        
        const element = document.getElementById(`ats_${id}`);
        const fullPath = article.salesArticleVariantColors
            .find(color => color.color.name === element.dataset.color).image.fullPath;
        const path = new URL(fullPath).pathname;
        const imageId = path.substring(path.indexOf('/product') + 1);
        return {
            requester: id,
            productName: article.description,
            colorName: element.dataset.color,
            imageUrl: imageId,
            savKey: element.dataset.savkey,
            categoryName: element.dataset.categoryname
        }
    }

    private subscribeToBookmark() {
        if (window && window.shell) {
            window.shell.subscribeTo('ESCID.ESPP.Bookmark.RequestArticleDataInjection',
                (requestData) => {
                    const payload = this.getPayloadById(requestData.requester);
                    window.shell.publishTo('ESCID.ESPP.Bookmark.ArticleDataInjection', payload);
                },
                'ESCID.ESPP.ArticleTileService');
        }
    }
}
