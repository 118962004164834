import * as React from 'react';
import classNames from 'classnames';
import { IFasSideContentProps } from '../fas-side-content/fas-side-content.d';
import styles from './sort-side-content.scss';
import * as helper from '../../Common/html-helper';
import { ISortProperty, ISortSideComponentState } from './sort-side-content.d';
import { SortContext } from '../globalState/sortContextProvider';
import { ISortContext } from '../globalState/sortContextProvider.d';
import { publish } from '../../Common/customEventHelper';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class SortSideComponent extends React.Component<IFasSideContentProps, ISortSideComponentState> {
    static contextType = SortContext;
    private tabNav: TabNavigationHelper;

    constructor(props: IFasSideContentProps) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;

        this.state = {
            componentMounted: false
        };
    }

    componentDidMount(): void {
        this.setState({
            componentMounted: true
        });
    }

    public render() {
        return (
            <SortContext.Consumer>
                {(sortContext) => (
                    <div className={styles.sort_list} ref={(el)=> this.tabNav.setGroupAtt(el)}>
                        {sortContext.sortPropertyList.map((property, index) => {
                            return (<div className={classNames(styles.list_item,
                                this.state.componentMounted && property.selected ? styles.selected_value : '')}
                                        data-testid='sort_list_item'
                                        key={index} onClick={() => { this.updateSortSelection(property); }}
                                        data-property={helper.decodeHTML(property.title)}
                                        ref={(el)=> this.tabNav.setFocusAtt(el)}
                                        onKeyDown={this.tabNav.handleKeyDown}>
                                {helper.decodeHTML(property.title)}
                            </div>);
                        })}
                    </div>
                )}
            </SortContext.Consumer>
        );
    }

    private updateSortSelection(property: ISortProperty) {
        const sortContext: ISortContext = this.context;
        sortContext.setSortSelected(property, true);

        publish('ESPP.MainSidePanel.ShouldClose', null);
    }
}
