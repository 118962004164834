import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CategoryPage from './categorypage/categorypage';

function hydrateCategoryPage() {
    if (typeof window.__PRELOADED_CATEGORY_PAGE_STATE__ !== 'undefined') {
        const data = JSON.parse(JSON.stringify(window.__PRELOADED_CATEGORY_PAGE_STATE__));
        delete window.__PRELOADED_CATEGORY_PAGE_STATE__;
        ReactDOM.hydrate(
            <CategoryPage {...data} suppressHydrationWarning />,
            document.getElementById('ats-categorypage'),
        );
    }
}

hydrateCategoryPage();
