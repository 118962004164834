import * as helper from '../../Common/html-helper';
import * as React from 'react';
import * as FasSideContentTypes from './fas-side-content.d';
import { IFilterProperty } from '../fas-bar/filter-property.d';
import { IFilterPropertyValue } from '../fas-bar/filter-property-value.d';
import styles from './fas-side-content.scss';
import XIcon from '../../Assets/svg/x';
import classNames from 'classnames';
import FilterContent from '../filterContent/filterContent';
import { Handler } from '../globalState/globalState';
import {
    IFilterSelectionChangedEvent,
    SelectedFiltersState,
    FilterSelectionChangedType
} from '../globalState/SelectedFiltersState';
import { ISelectedFilter } from '../globalState/selectedFilters.d';
import { convertFromServiceToReactWorld } from '../globalState/ServiceSelectedFilterConverter';
import {
    AvailableFiltersState,
    IFilterModelChangedEvent,
    ICategoryData
} from '../globalState/AvailableFiltersState';
import AvailabilityFilter from '../availabilityFilter/availabilityFilter';
import ArrowDownIcn from '../../Assets/svg/arrow_down';
import SpinnerComponent from '../spinnerComponent/spinnerComponent';
import TooltipComponent from '../tooltipComponent/tooltipComponent';
import * as Constants from '../../Common/constants';
import { Scrollbars } from 'react-custom-scrollbars';
import { SortContext } from '../globalState/sortContextProvider';
import { ISortContext } from '../globalState/sortContextProvider.d';
import { publish, subscribe } from '../../Common/customEventHelper';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class FasSideContent extends React.Component<FasSideContentTypes.IFasSideContentProps,
    FasSideContentTypes.IFasSideContentState> {
    static contextType = SortContext;

    private readonly availableFiltersState: AvailableFiltersState;
    private readonly selectedFilterState: SelectedFiltersState;
    private readonly componentName: string = 'fas-side-content';
    private filterContentRef = React.createRef<FilterContent>();
    private selectedFiltersRef = React.createRef<HTMLDivElement>();
    private gradientContainerRight = React.createRef<HTMLDivElement>();
    private gradientContainerLeft = React.createRef<HTMLDivElement>();
    private tabNav: TabNavigationHelper;

    constructor(props: FasSideContentTypes.IFasSideContentProps) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;
        const selectedFilters = convertFromServiceToReactWorld(
            props.initialUserSelectedFilters,
            props.initialFilterModel);

        this.removeAllFilters = this.removeAllFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addSelectedFilter = this.addSelectedFilter.bind(this);
        this.removeSelectedFilter = this.removeSelectedFilter.bind(this);
        this.handleFilterIsAvailableChange = this.handleFilterIsAvailableChange.bind(this);
        this.updateTooltip = this.updateTooltip.bind(this);
        this.updateGlovesSort = this.updateGlovesSort.bind(this);
        this.getLastSelectedFilterName = this.getLastSelectedFilterName.bind(this);
        this.onHeaderClick = this.onHeaderClick.bind(this);
        this.toggleSelectedFiltersView = this.toggleSelectedFiltersView.bind(this);
        this.handleSortContextChange = this.handleSortContextChange.bind(this);

        const categoryData: ICategoryData = {
            categoryPath: this.props.categoryPath,
            navigationKey: this.props.navigationKey,
            seoSlug: this.props.seoSlug,
        };

        this.availableFiltersState = new AvailableFiltersState(this.props.globalState, categoryData, this.componentName,
            this.props.initialFilterModel, selectedFilters, this.props.searchTerm, this.props.kiosk);
        this.availableFiltersState.registerOnStateChanged(this.onAvailableFiltersChanged.bind(this));
        this.selectedFilterState = this.availableFiltersState.selectedFilterState;
        this.props.globalState.selectedFiltersState = this.selectedFilterState;
        this.selectedFilterState.registerOnStateChanged(this.onSelectedFiltersChanged.bind(this));

        this.state = {
            collapsedFilter: this.getLastSelectedFilterName(selectedFilters),
            mspOpen: false,
            filterModel: props.initialFilterModel,
            selectedFilters,
            selectedTooltipText: null,
            showTooltip: false,
            tooltipImageName: null,
            componentMounted: false,
            selectedFiltersExpanded: false,
        };

        subscribe('ESPP.MainSidePanel.Closed',
            () => {
                this.setState({ mspOpen: false });
                this.resetState();
                if (this.props.isMobile) {
                    this.availableFiltersState.restoreToGlobalState();
                }
            });

        subscribe('ESPP.MainSidePanel.Opened',
            () => {
                this.setState({ mspOpen: true });
                setTimeout(() => {
                    this.setSelectedFilterView();
                    }, 500);
            });

        subscribe(
            'ESPP.MainSidePanel.TabSwitchOff',
            (payload: CustomEvent) => {
                if (payload.detail === 'Filter')
                    this.resetState();
            });
    }

    componentDidMount(): void {        
        // restore history.scrollRestoration
        // it was removed during filter add/remove on mobile devices
        // this is mainly for apple devices
        if(this.props.isMobile) {
            setTimeout(() => {
                history.scrollRestoration = 'auto';
            }, 500);
        }

        this.setState({
            componentMounted: true
        });

        const sortContext: ISortContext = this.context;
        sortContext.addOnChangeSideEffect(this.handleSortContextChange);
    }

    componentWillUnmount() {
        const sortContext: ISortContext = this.context;
        sortContext.removeOnChangeSideEffect(this.handleSortContextChange);
    }

    public handleSortContextChange() {
        this.setSelectedFilterView();
    }

    private setSelectedFilterView(): void {
        const selectedFiltersContainer: HTMLDivElement = this.selectedFiltersRef.current;
        const gradientContainerRight: HTMLDivElement = this.gradientContainerRight.current;
        const gradientContainerLeft: HTMLDivElement = this.gradientContainerLeft.current;

        if (!selectedFiltersContainer)
            return;

        const filtersList: HTMLDivElement = selectedFiltersContainer.querySelector('.fas_selected_filters');
        if (!filtersList)
            return;

        if (this.props.isMobile) {
            if (filtersList.clientWidth < filtersList.scrollWidth) {
                gradientContainerRight.classList.add('fas_gradient_container_show');
                gradientContainerLeft.classList.add('fas_gradient_container_show');
            }
            else {
                gradientContainerRight.classList.remove('fas_gradient_container_show');
                gradientContainerLeft.classList.remove('fas_gradient_container_show');
            }
        }

        else {
            if (filtersList.clientHeight + 18 < filtersList.scrollHeight ||
                (filtersList.classList.contains('fas_expanded_view') &&
                    filtersList.clientHeight === filtersList.scrollHeight && filtersList.clientHeight !== 92))
                selectedFiltersContainer.classList.add('fas_overflown_container');
            else {
                selectedFiltersContainer.classList.remove('fas_overflown_container');
                filtersList.classList.remove('fas_expanded_view');
            }
        }
    }

    public render() {
        const sortContext: ISortContext = this.context;
        const normalFilters = this.state.filterModel.filters ?
            this.state.filterModel.filters.filter((x) => x.name !== Constants.DELIVERY_TIME) : [];
        const isDeliveryTimeAvailable = this.state.filterModel && this.state.filterModel.filters
            && this.state.filterModel.filters.filter((x) => x.name === Constants.DELIVERY_TIME).length > 0;
        const showSelectedFiltersWrapper = (this.state.selectedFilters && (this.state.selectedFilters.length > 0
            || sortContext.activeGlovesSortPropValues.length > 0));
        return (
            <div className={classNames(styles.side_content, helper.getPortalSelector(this.props.isGlobal))}>
                {
                    this.state.filterModel.filters && this.state.filterModel.filters.length > 0 &&
                    <>
                        <SpinnerComponent
                            context='fas-side-content'
                            isFixedLayout={false}
                            overlayBreadcrumb={false}
                            globalState={this.props.globalState} />
                        <TooltipComponent showTooltip={this.state.showTooltip}
                            text={this.state.selectedTooltipText}
                            imageName={this.state.tooltipImageName}
                            updateTooltip={this.updateTooltip} />
                    </>
                }
                {
                    normalFilters.length > 0
                        ? (
                            <>
                                {this.renderAllAvailableFilters(showSelectedFiltersWrapper)}
                            </>
                        ) :
                        (
                            <div className={styles.no_filter}>
                                <div>
                                    {helper.decodeHTML(this.props.l10n.noFurtherFilterDimensions)}
                                </div>
                            </div>
                        )
                }
                {
                    this.state.componentMounted &&
                    <div className={styles.footer}>
                        {
                            showSelectedFiltersWrapper
                            && (
                                <div className={styles.selected_filters_wrapper}
                                    ref={this.selectedFiltersRef}>
                                    {
                                        this.props.isMobile &&
                                        <div className={classNames(styles.gradient_container)}
                                            data-testid='gradient_container_left'
                                            ref={this.gradientContainerLeft}>
                                            <div className={styles.gradient}
                                                data-testid='gradient_left' />
                                        </div>
                                    }
                                    <div className={classNames(styles.selected_filters,
                                        { [styles.expanded_view]: this.state.selectedFiltersExpanded })}>
                                        {
                                            // gloves sort properties:
                                            sortContext.activeGlovesSortPropValues.map((gSort) => (
                                                <div key={gSort.title}
                                                    className={styles.result_label}
                                                    data-testid='result_label_glove'
                                                    onClick={this.removeGlovesSort.bind(this, gSort)}>
                                                    {
                                                        gSort.title
                                                    }
                                                    <span className={styles.close_icon}><XIcon /></span>
                                                </div>
                                            ))
                                        }
                                        {
                                            this.selectedFilterState.sortSelectedFilters().map((filter: ISelectedFilter) =>
                                            (
                                                <div key={filter.dimension.name + ':' + filter.value.slug}
                                                    className={styles.result_label}
                                                    data-testid='result_label_filter'
                                                    onClick={this.removeSelectedFilter.bind(this, filter)}>
                                                    {this.selectedFilterName(filter)}
                                                    <span className={styles.close_icon}><XIcon /></span>
                                                </div>
                                            ))
                                        }
                                        {
                                            !this.props.isMobile &&
                                            <div className={classNames(styles.expand_button)}
                                                data-testid='expand_button'
                                                onClick={this.toggleSelectedFiltersView}>
                                                <div className={styles.gradient_top}></div>
                                                <ArrowDownIcn />
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.props.isMobile &&
                                        <div className={classNames(styles.gradient_container, styles.right)}
                                            data-testid='gradient_container_right'
                                            ref={this.gradientContainerRight}>
                                            <div className={classNames(styles.gradient, styles.right)}
                                                data-testid='gradient_right' />
                                        </div>
                                    }
                                </div>
                            )
                        }
                        {
                            isDeliveryTimeAvailable &&
                            <div className={styles.switch_wrapper}>
                                {this.renderAvailableFilter()}
                            </div>
                        }
                        <div className={styles.button_wrapper} ref={(el)=> this.tabNav.setGroupAtt(el)}>
                            <div className={classNames(styles.cancel_button, {
                                [styles.disabled_button]: !this.selectedFilterState.anyFiltersSelected()
                                    && (sortContext.activeGlovesSortPropValues.length === 0),
                            })}
                                ref={(el)=> this.tabNav.setFocusAtt(el)}
                                data-testid='cancel_button'
                                onClick={this.removeAllFilters}
                                onKeyDown={this.tabNav.handleKeyDown}>
                                <p>{helper.decodeHTML(this.props.l10n.clearAllFilters)}</p>
                            </div>
                            {this.getMatchingArticleCounter()}
                        </div>
                    </div>
                }
            </div >
        );
    }

    public handleFilterIsAvailableChange(): void {
        if (!this.state.filterModel.filters)
            return;

        const filter: IFilterProperty | undefined
            = this.state.filterModel.filters.find((x) => x.name === Constants.DELIVERY_TIME);
        const isSelected: boolean = this.isAvailableFilterActive();
        if (typeof filter !== 'undefined')
            if (isSelected) {
                const selectedFilter = {
                    dimension: filter,
                    value: filter.filterPropertyValues[0],
                } as ISelectedFilter;
                this.removeSelectedFilter(selectedFilter);
            } else
                this.addSelectedFilter(filter, filter.filterPropertyValues[0]);
        else {
            // eslint-disable-next-line no-console
            console.warn('Data inconsistent! delivery_time filter should always available base on design');
        }
    }

    public updateTooltip(visibility: boolean, text: string, imageName?: string) {
        this.setState({
            selectedTooltipText: text,
            showTooltip: visibility,
            tooltipImageName: imageName,
        });
    }

    private toggleSelectedFiltersView(): void {
        const selectedFiltersContainer: HTMLDivElement = this.selectedFiltersRef.current;
        if (selectedFiltersContainer) {
            const filtersList: HTMLDivElement = selectedFiltersContainer.querySelector('.fas_selected_filters');
            if (filtersList) {
                this.setState({ selectedFiltersExpanded: !this.state.selectedFiltersExpanded });
            }
        }
    }

    private selectedFilterName(filter: ISelectedFilter): string {
        const isDeliveryTime: boolean = filter.dimension.name === Constants.DELIVERY_TIME;
        if (isDeliveryTime)
            return filter.value.title.substring(0,
                filter.value.title.indexOf(','));
        else
            if (filter.dimension.applyDimensionTitleInActiveFilter)
                return filter.dimension.title + ': ' + filter.value.title;
            else
                return filter.value.title;
    }

    private globalyRemoveSelectedFilter(filter: ISelectedFilter) {
        this.selectedFilterState.globalRemoveFilter(filter);
    }

    private removeGlovesSort(gSort: IFilterPropertyValue): void {
        const sortContext: ISortContext = this.context;
        sortContext.setGloveRating(gSort, 0, !this.props.isMobile, !this.props.isMobile,
            !this.props.isMobile);
    }

    private getMatchingArticleCounter() {
        const result: string = this.state.filterModel.matchingArticleCount > 1 ?
            this.props.l10n.showItems :
            this.props.l10n.showItem;

        const strings = result.split('{0}');
        let surfix = '';
        let prefix = '';
        if (strings.length > 1) {
            prefix = strings[0];
            surfix = strings[1];
        } else
            surfix = strings[0];

        return (
            <div ref={(el)=> this.tabNav.setFocusAtt(el)} 
                className={styles.apply_button}
                data-testid='apply_button'
                onClick={this.applyFilters}
                onKeyDown={this.tabNav.handleKeyDown}>
                {prefix} <b>{this.state.filterModel.matchingArticleCount}</b> {surfix}
            </div>
        );
    }

    private renderAllAvailableFilters(showSelectedFiltersWrapper: boolean) {
        if (this.state.filterModel !== null && this.state.filterModel.filters
            && this.state.componentMounted) {
            const displayFilters = this.state.filterModel.filters.filter((x) => x.name !== Constants.DELIVERY_TIME);
            return (
                <div className={classNames(styles.content,
                    { [styles.content_shrink_selected_filters_shown]: showSelectedFiltersWrapper },
                    { [styles.content_shrink_selected_filters_expanded]: this.state.selectedFiltersExpanded }
                )}>
                    <Scrollbars
                        autoHide={true}
                        autoHideTimeout={1000}
                        hideTracksWhenNotNeeded={true}
                        renderTrackVertical={() => <div className={styles.scrollbar} />}
                        renderThumbVertical={() => <div className={styles.thumb} />}>
                        <div className={styles.filters_wrapper} ref={(el)=> this.tabNav.setGroupAtt(el)}>
                            {displayFilters.map((filter) => this.renderFilterProperty(filter))}
                        </div>
                    </Scrollbars>
                </div>
            );
        }

        return (
            <div>
                no filters found
            </div>
        );
    }

    private renderFilterProperty(filter: IFilterProperty) {
        const sortContext: ISortContext = this.context;
        const isCollapsed: boolean = this.state.collapsedFilter === filter.name;
        return (
            <div className={styles.filters} key={filter.name}>
                <div ref={(el)=> this.tabNav.setFocusAtt(el)} 
                    className={classNames(
                    styles.filter_header,
                    {
                        [styles.disabled_filter]: filter.allDisabled,
                        [styles.collapsed]: isCollapsed,
                    })}
                    data-testid='filter_header'
                    onClick={() => { this.onHeaderClick(filter); }}
                    onKeyDown={this.tabNav.handleKeyDown}
                    >
                    <div className={styles.filter_name} >
                        {filter.title}
                    </div>
                    {
                        filter.name === Constants.GLOVES ? (
                            <div className={styles.selected_filters}>
                                {
                                    sortContext.activeGlovesSortPropValues.map((gSort) => {
                                        return gSort.title;
                                    }).join(', ')
                                }
                            </div>
                        )
                            : (
                                this.state.selectedFilters.filter((f) => f.dimension.name === filter.name) &&
                                <div className={styles.selected_filters}>
                                    {this.state.selectedFilters.filter(
                                        (f) => f.dimension.name.split(':')[0] === filter.name)
                                        .map((value) => {
                                            return value.value.title;
                                        }).join(', ')}
                                </div>
                            )
                    }
                    <ArrowDownIcn />
                </div>
                {
                    isCollapsed &&
                    <div className={styles.filter_container}>
                        <FilterContent
                            ref={this.filterContentRef}
                            dimension={filter}
                            addSelectedFilter={this.addSelectedFilter}
                            selectedFilters={this.state.selectedFilters}
                            removeSelectedFilter={this.removeSelectedFilter}
                            updateTooltip={this.updateTooltip}
                            l10n={this.props.l10n}
                            isMobile={this.props.isMobile}
                        />
                    </div>
                }
            </div>
        );
    }

    private resetState() {
        this.updateTooltip(false, '');
        const originalLastSelectedFilterName = this.getLastSelectedFilterName(this.state.selectedFilters);
        this.setState({ collapsedFilter: originalLastSelectedFilterName });
    }

    private updateGlovesSort(): void {
        const sortContext: ISortContext = this.context;
        sortContext.applyContextChanges();
    }

    private renderAvailableFilter() {
        if (!this.state.filterModel.filters)
            return null;

        const isAvailable = this.isAvailableFilterActive();
        const tooltipText = this.props.l10n.deliveryTime;
        return (
            <AvailabilityFilter
                isAvailable={isAvailable}
                setIsAvailableFilter={this.handleFilterIsAvailableChange}
                mltTitle={this.props.l10n.readyForImmediateShipmentDeliveryDays}
                mltTitleSmall={this.props.l10n.readyForImmediateShipment}
                tooltipText={tooltipText}
                updateTooltip={this.updateTooltip}
            />
        );
    }

    private isAvailableFilterActive(): boolean {
        const deliveryTimeFilterIndex = this.state.selectedFilters.findIndex(
            (x) => x.dimension.name === Constants.DELIVERY_TIME);
        return deliveryTimeFilterIndex >= 0;
    }

    private onHeaderClick(filter: IFilterProperty) {
        if (!filter.allDisabled)
            if (this.state.collapsedFilter === filter.name)
                this.setState({ collapsedFilter: null });
            else
                this.setState({ collapsedFilter: filter.name });
    }

    private applyFilters() {
        publish('ESPP.MainSidePanel.ShouldClose', null);

        if (this.props.isMobile) {
            history.scrollRestoration = 'manual'; // mainly for iphone scroll feature
            const sortContext: ISortContext = this.context;
            sortContext.applyContextChanges(null, true, false, true);
            this.availableFiltersState.updateGlobalState();
        }
    }

    private removeAllFilters() {
        const sortContext: ISortContext = this.context;
        sortContext.removeAllGloveSortValues(true, !this.props.isMobile, true); // skip articlelist update on mobile

        if (this.props.isMobile) {
            history.scrollRestoration = 'manual'; // mainly for iphone scroll feature
        }

        this.selectedFilterState.globalRemoveAllFilters();
    }

    private addSelectedFilter(dimension: IFilterProperty, value: IFilterPropertyValue) {
        // activate loading spinner:
        this.selectedFilterState.addFilter({ dimension, value } as ISelectedFilter);
    }

    private removeSelectedFilter(filter: ISelectedFilter) {
        // activate loading spinner:
        this.selectedFilterState.removeFilter(filter);
    }

    private onSelectedFiltersChanged: Handler<IFilterSelectionChangedEvent> = (event: IFilterSelectionChangedEvent) => {
        this.setState({ selectedFilters: event.selectedFilters });

        if (event.type === FilterSelectionChangedType.GlobalUpdate) {
            const collapsedFilterName: string = this.getLastSelectedFilterName(event.selectedFilters);
            this.setState({ collapsedFilter: collapsedFilterName });
        }

        if (event.type === FilterSelectionChangedType.FilterAdded ||
            event.type === FilterSelectionChangedType.FilterRemoved ||
            event.type === FilterSelectionChangedType.GlobalFilterRemoved) {
            setTimeout(() => { this.setSelectedFilterView(); }, 500);
        }

        if (event.type === FilterSelectionChangedType.GlobalAllFilterRemoved)
            publish('ESPP.MainSidePanel.ShouldClose', null);
    }

    private getLastSelectedFilterName(selectedFilters: ISelectedFilter[]): string {
        let result: string = null;

        if (selectedFilters.length > 0) {
            // Set last filter name:
            result = selectedFilters[selectedFilters.length - 1].dimension.name.split(':')[0];

            // filters in side panel might not be rendered at the very beginning:
            if (this.filterContentRef && this.filterContentRef.current) {
                // Set last sub filter name:
                const selectedValue = selectedFilters.filter((value) =>
                    value.dimension.name.split(':')[0] === result);
                const lastSelectedSubFilterName: string =
                    selectedValue.length > 0 ? selectedValue.pop().dimension.name : null;
                this.filterContentRef.current.setState({ collapsedSubFilter: lastSelectedSubFilterName });
            }
        }

        return result;
    }

    private onAvailableFiltersChanged: Handler<IFilterModelChangedEvent> = (event: IFilterModelChangedEvent) => {
        this.setState({ filterModel: event.filterModel });
    }
}
