export default class TabNavigationHelper {
    private static _instance: TabNavigationHelper;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public setGroupAtt(el: HTMLDivElement): void {
        if (el) {
            if (!el.hasAttribute('group')) {
                el.toggleAttribute('group');
            }
        }
    }

    public setFocusAtt(el: HTMLDivElement | HTMLSpanElement): void {
        if(el) {
            el.setAttribute('focusable', '');
            el.setAttribute('order', '');
        }
    }

    public setModalAtt(el: HTMLDivElement): void {
        if(el) {
            el.setAttribute('Modal', '');
        }
    }

    public handleKeyDown(event): void {
        event.stopPropagation(); // avoid triggering parent:

        // prevent page jump down on Space btn., click
        if (event.key === ' ') //'Space'
            event.preventDefault();
    }
}
