import * as React from 'react';
import classNames from 'classnames';
import styles from './gender-value.scss';
import { IFilterValueProps } from '../filterValue/filter-value.d';
import ManIcn from '../../../Assets/svg/icon_herren';
import WomanIcn from '../../../Assets/svg/icon_damen';
import ChildIcn from '../../../Assets/svg/icon_kinder';
import TabNavigationHelper from '../../../Common/tabNavigationHelper';

export default class GenderValue extends React.Component<IFilterValueProps> {
    private tabNav: TabNavigationHelper;
    constructor(props) {
        super(props);
        this.state = {};
        this.tabNav = TabNavigationHelper.instance;
        this.onValueClicked = this.onValueClicked.bind(this);
    }

    public render() {
        return (
            <div className={classNames(styles.filter_gender_value,
                {
                     [styles.selected_value]: this.props.isSelected,
                     [styles.disabled_value]: this.props.filterPropertyValue.articlesAmount === 0,
                })}
                data-testid='filter_gender_value'
                onClick={this.onValueClicked}
                ref={(el)=> this.tabNav.setFocusAtt(el)}
                onKeyDown={this.tabNav.handleKeyDown}
            >
                {this.props.filterPropertyValue.iconSrc ?
                    (
                        <div className={styles.icon_container}>
                            <img src={this.props.filterPropertyValue.iconSrc} loading='lazy'/>
                        </div >
                    )
                    : null
                }
                <div className={styles.gender_image}>
                    {
                        this.props.filterPropertyValue.id === '2' ? <ManIcn /> : null
                    }
                    {
                        this.props.filterPropertyValue.id === '3' ? <WomanIcn /> : null
                    }
                    {
                        this.props.filterPropertyValue.id === '4' ? <ChildIcn /> : null
                    }
                </div>
                <div className={styles.filter_title}>
                    {this.props.filterPropertyValue.title}
                </div>
                <div className={styles.article_amount}>{this.props.filterPropertyValue.articlesAmount}</div>
            </div>
        );
    }

    private onValueClicked() {
        if (this.props.filterPropertyValue.articlesAmount === 0)
            return;

        if (this.props.isSelected)
            this.props.removeSelectedFilter(this.props.filter);
        else
            this.props.addSelectedFilter(this.props.dimension, this.props.filterPropertyValue);
    }
}
