import * as ArticleCountTypes from './article-count.d';
import * as React from 'react';
import styles from './article-count.scss';

export default class ArticleCount extends React.Component<ArticleCountTypes.IArticleCountProps> {
    constructor(props: ArticleCountTypes.IArticleCountProps) {
        super(props);
        this.state = {};
    }

    public render() {
        if (this.props.articleNumber !== null)
            return (
                <div className={styles.article_count}>
                    {this.getGlobalArticleCountString()}
                </div>
            );
        else
            return null;
    }

    private getGlobalArticleCountString(): string {
        const unitString = this.props.globalState.filterModel.matchingArticleCount > 1
            ? this.props.l10n.articles
            : this.props.l10n.article;
        return this.props.globalState.filterModel.matchingArticleCount?.toString()
            + ' ' + unitString;
    }
}
