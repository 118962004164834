import * as React from 'react';
import { IFilterContentProp, IFilterContentState } from './filterContent.d';
import styles from './filterContent.scss';
import classNames from 'classnames';
import FilterValuesGroup from './filterValuesGroup/filterValuesGroup';
import * as Constants from '../../Common/constants';
import PlusIcn from '../../Assets/svg/plus';
import MinusIcn from '../../Assets/svg/minus';
import { IFilterProperty } from '../fas-bar/filter-property.d';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class FilterContent extends React.Component<IFilterContentProp, IFilterContentState> {
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.state = {
            collapsedSubFilter: this.lastSelectedSubFilterName(),
        };

        this.tabNav = TabNavigationHelper.instance;

        this.onHeaderClick = this.onHeaderClick.bind(this);
    }

    public componentDidMount() {
        if (this.props.dimension.subProperties && this.props.dimension.subProperties.length > 0)
            if (this.state.collapsedSubFilter !== null) {
                const collapsedProperty =
                    this.props.dimension.subProperties.find((property) =>
                        property.name === this.state.collapsedSubFilter);
                this.handleContentCollapse(collapsedProperty);
            } else if (this.props.dimension.subProperties.length === 1)
                this.handleContentCollapse(this.props.dimension.subProperties[0]);
    }

    public componentWillUnmount() {
        this.props.updateTooltip(false, '');
    }

    // private handleKeyDown(event, subProperty: IFilterProperty): void {
    //     // prevent page jump down on Space btn., click
    //     if (event.key === ' ') {//'Space'
    //         event.preventDefault();
    //         this.onHeaderClick(subProperty);
    //     }
    // }

    public render() {
        // sub filters
        if (this.props.dimension.subProperties && this.props.dimension.subProperties.length > 0)
            return (
                <div className={styles.filter_content}> {
                    this.props.dimension.subProperties.map((subProperty, index) => {
                        return (
                            <div className={classNames(styles.sub_filter_wrapper,
                                {
                                    [styles.disabled_sub_filter]: subProperty.allDisabled,
                                },
                            )} key={index}>
                                <div className={classNames(styles.sub_filter_header,
                                        {
                                            [styles.cursor]: this.props.dimension.subProperties.length === 1,
                                        },
                                        {
                                            [styles.collapsed]: this.state.collapsedSubFilter === subProperty.name
                                                || this.props.dimension.subProperties.length === 1,
                                        },
                                    )}
                                    data-testid='sub_filter_header'
                                    onClick={() => { this.onHeaderClick(subProperty); }} 
                                    ref={(el)=> this.tabNav.setFocusAtt(el)}
                                    onKeyDown={this.tabNav.handleKeyDown}>
                                    <div className={styles.sub_filter_icon}>
                                        {this.state.collapsedSubFilter === subProperty.name ||
                                            this.props.dimension.subProperties.length === 1
                                            ? <MinusIcn /> : <PlusIcn />}
                                    </div>
                                    <div className={styles.sub_filter_name}>
                                        {subProperty.title}
                                    </div>
                                </div>

                                <div className={classNames(
                                    {
                                        [styles.hidden]: this.state.collapsedSubFilter !== subProperty.name &&
                                            this.props.dimension.subProperties.length !== 1,
                                    })}>
                                    <FilterValuesGroup filterProperty={subProperty}
                                        selectedFilters={this.props.selectedFilters}
                                        addSelectedFilter={this.props.addSelectedFilter}
                                        removeSelectedFilter={this.props.removeSelectedFilter}
                                        updateTooltip={this.props.updateTooltip}
                                        l10n={this.props.l10n}
                                        isMobile={this.props.isMobile}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            );
        else // regular filter
            return (
                <div className={classNames(styles.filter_content, styles.single_filter_content,
                    { [styles.filter_collection_line]: this.props.dimension.name === Constants.COLLECTION_LINE },
                    { [styles.filter_colour]: this.props.dimension.name === Constants.COLOUR },
                )}>
                    <FilterValuesGroup filterProperty={this.props.dimension}
                        selectedFilters={this.props.selectedFilters}
                        addSelectedFilter={this.props.addSelectedFilter}
                        removeSelectedFilter={this.props.removeSelectedFilter}
                        updateTooltip={this.props.updateTooltip}
                        l10n={this.props.l10n}
                        isMobile={this.props.isMobile}
                    />
                </div>
            );
    }

    private onHeaderClick(subProperty: IFilterProperty) {
        if (this.state.collapsedSubFilter === subProperty.name || subProperty.allDisabled === true)
            this.setState({ collapsedSubFilter: null });
        else
            this.handleContentCollapse(subProperty);
    }

    private handleContentCollapse(subProperty: IFilterProperty) {
        this.setState({ collapsedSubFilter: subProperty.name });
    }

    private lastSelectedSubFilterName(): string {
        if (this.props.selectedFilters.length > 0) {
            const selectedValue = this.props.selectedFilters.filter((value) =>
                value.dimension.name.split(':')[0] === this.props.dimension.name);
            return selectedValue.length > 0 ? selectedValue.pop().dimension.name : null;
        } else
            return null;
    }
}
