import * as React from 'react';
import * as helper from '../../Common/html-helper';
import classNames from 'classnames';
import styles from './availabilityFilter.scss';
import InfoIcon from '../../Assets/svg/info';
import { IAvailabilityFilter, IAvailabilityFilterState } from './availabilityFilter.d';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class AvailabilityFilter extends React.Component<IAvailabilityFilter, IAvailabilityFilterState> {
    private tabNav: TabNavigationHelper;

    constructor(props: IAvailabilityFilter) {
        super(props);
        this.state = {};

        this.tabNav = TabNavigationHelper.instance;

        this.handleFiltersIsAvailableChanged = this.handleFiltersIsAvailableChanged.bind(this);
        this.onTooltipClicked = this.onTooltipClicked.bind(this);
    }

    public render() {
        const af_text = helper.decodeHTML(this.props.mltTitle);
        return (
            <div className={styles.available_filter_container}
                ref={(el)=> this.tabNav.setGroupAtt(el)}>
                <div className={styles.af_switch}
                    ref={(el)=> this.tabNav.setFocusAtt(el)}
                    data-testid='af_switch'
                    onClick={this.handleFiltersIsAvailableChanged}
                    onKeyDown={this.tabNav.handleKeyDown}>
                    <label htmlFor={'af_isAvailable'}
                        data-testid='lbl_for_af_isAvailable'
                        style={{ visibility: 'hidden', height: 0, width: 0 }}>
                        {af_text}
                    </label>
                    <input type='checkbox' checked={this.props.isAvailable}
                        id='af_isAvailable'
                        onChange={this.handleFiltersIsAvailableChanged} />
                    <span className={classNames(styles.slider, styles.round)}></span>
                </div>
                <div className={styles.af_text}>{af_text}</div>
                <div className={styles.af_text_small}>{helper.decodeHTML(this.props.mltTitleSmall)}</div>
                <div className={styles.af_info_icon}
                    data-testid='af_info_icon'
                    onClick={this.onTooltipClicked} >
                    <InfoIcon />
                </div>
            </div>
        );
    }

    private handleFiltersIsAvailableChanged(): void {
        if (this.props.setIsAvailableFilter)
            this.props.setIsAvailableFilter();
    }

    private onTooltipClicked(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(true, this.props.tooltipText);
    }
}
